import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from '../firebaseConfig/firebase';
import { signOut } from "firebase/auth";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';

import ListGroup from 'react-bootstrap/ListGroup';

import Login from './Login';

const OnePedido = () => {

    const navigate = useNavigate();
    const [valido, setValido] = useState();
    const [lista, setLista] = useState([]);
    const [orderLine, setOrderLine] = useState([]);
    const [show, setShow] = useState(false);
    const [cantidades, setCantidades] = useState();
    const [check, setCheck] = useState();
    const [estado, setEstado] = useState();
    const [nextStep, setNextStep] = useState();

    // Obtener la cadena de consulta de la URL
    const queryString = window.location.search;
    // Crear un objeto URLSearchParams
    const urlParams = new URLSearchParams(queryString);
    // Obtener el valor del parámetro 'name'
    const name = urlParams.get('name');
    const proyecto = urlParams.get('proyecto');
    const alumno1 = urlParams.get('alumno1');
    const rental_status = urlParams.get('rental_status');
    const return_date = urlParams.get('return_date');
    const start_date = urlParams.get('start_date');
    const alumno2 = urlParams.get('alumno2');
    const profesor = urlParams.get('profesor');


    const [texto, setTexto] = useState('');

    const manejarCambio = (index, e) => {
        const nuevoValor = e.target.value;
        const nuevasCantidades = [...cantidades];
        nuevasCantidades[index] = nuevoValor;
        setCantidades(nuevasCantidades);
    };
    const manejarCambioCheck = (index, event) => {
        const updatedCheck = [...check];
        updatedCheck[index] = event.target.checked; // Actualiza el estado del checkbox
        setCheck(updatedCheck); // Establece el nuevo estado
    };

    const manejarEnvio = (e) => {
        e.preventDefault();
        alert(`Texto ingresado: ${cantidades} ${check}`);
    };


    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/Login");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    const handleHome = () => {
        navigate("/");
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                user.getIdToken().then((token) => {
                    //console.log(token);
                    saleOrderLine(name);
                    if (rental_status == 'draft') {
                        setEstado('Presupuesto')
                        setNextStep('Confirmar')
                    }
                    if (rental_status == 'pickup') {
                        setEstado('Confirmado')
                        setNextStep('Recoger')
                    }
                    if (rental_status == 'return') {
                        setEstado('Recogido')
                        setNextStep('Devolver')
                    }
                    if (rental_status == 'returned') {
                        setEstado('Devuelto')
                        setNextStep('Cancelar')
                    }
                })
                setValido(true);
            } else {
                setValido(false);
                console.log("user is logged out")
            }
        });

    }, [])

    const saleOrderLine = (name) => {
        const urlServidor = 'https://appsescac.com/getSaleOrderLine?orderid=' + name
        const headers = {
            //'Authorization': 'Bearer ' + token // Adjuntar el token al encabezado de autorización
        };
        //console.log(urlServidor)
        axios.get(urlServidor, { headers })
            .then(response => {

                const res = response.data.data.value;
                const docs = []
                console.log(res)
                res.forEach((i) => {
                    docs.push({
                        id: i.id, name: name, producto: i.product_id[1], cantidad: i.product_uom_qty,
                        check: i.x_studio_check, descripcion: i.x_studio_descripcin
                    })
                })
                //console.log(docs)
                setOrderLine(docs);
                setCantidades(docs.map(p => p.cantidad));
                setCheck(docs.map(p => p.check))

            })
            .catch(error => {
                console.log(error);
            });
        //console.log(name)
    }

    const modificarDatos = (e) => {
        e.preventDefault();
        orderLine.forEach((x, index) => {
            x.cantidad = cantidades[index];
            x.check = check[index]
        });

        orderLine.map((i) => {
            var comprobar = 0;
            console.log(i)
            if (i.check == true) {
                comprobar = 1;
            } else if (i.check == false) {
                comprobar = 0;
            }
            console.log(comprobar)
            const urlServidor = 'https://appsescac.com/postSaleOrderLine?id=' + i.id + '&cantidad=' + i.cantidad + '&check=' + comprobar
            const headers = {/*'Authorization': 'Bearer ' + token // Adjuntar el token al encabezado de autorización*/ };
            //console.log(urlServidor)
            axios.post(urlServidor, { headers })
                .then(response => {
                    const datos = response.data; // Accede a los datos de la respuesta
                    console.log(datos);
                })
                .catch(error => {
                    console.log(error);
                });
        })
    };

    const modificarEstado = (e) => {
        e.preventDefault();
        var estadoTotal = ''
        if(nextStep == 'Recoger') {estadoTotal = 'return'}
        const urlServidor = 'http://localhost:3000/postCambioEstado?id=' + {name} + '&estado=' + estadoTotal
        const headers = {/*'Authorization': 'Bearer ' + token // Adjuntar el token al encabezado de autorización*/ };
        //console.log(urlServidor)
        axios.post(urlServidor, { headers })
            .then(response => {
                const datos = response.data; // Accede a los datos de la respuesta
                console.log(datos);
            })
            .catch(error => {
                console.log(error);
            });

    };


    /* APARADOR */
    if (valido) return (
        <>

            <Container>
                <Row>
                    <Col>
                        <button className="mt-2 ms-2" style={{ width: '70px' }} onClick={handleHome}>
                            Volver
                        </button>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-end">

                            <button className="mt-2 ms-2" style={{ width: '70px' }} onClick={handleLogout}>
                                Salir
                            </button>

                        </div>
                    </Col>
                </Row>


                <Row className="mt-0 mt-lg-4 p-3 p-lg-0 p-md-0 p-sm-0 mt-sm-2 border-bottom border-2 border-dark mb-4">
                    <Col><h2 className="m-0 fw-bold ">Pedido Nº {name}</h2></Col>
                    <Col className="d-flex justify-content-end">{estado} ---
                        <Button variant="secondary" onClick={modificarEstado}>
                            {nextStep}
                        </Button>
                    </Col>
                </Row>
                <div className="border mb-3">
                    <Row>
                        <Col>Alumno 1: {alumno1}</Col>
                        <Col>{proyecto}</Col>
                    </Row>
                    <Row>
                        <Col>Alumno 2: {alumno2}</Col>
                        <Col>Recogida: {start_date}</Col>
                    </Row>
                    <Row>
                        <Col>Profesor: {profesor}</Col>
                        <Col>Devolución: {return_date}</Col>
                    </Row>
                </div>
                {/*<Row className="border-bottom mb-2 pb-2">
                    <Col lg={8} className="text-center"><b>PRODUCTO</b></Col>
                    <Col lg={2} className="text-center " style={{ letterSppacing: '10px' }}><b>CANTIDAD</b></Col>
                    <Col lg={2} className="text-center "><b>CHECK</b></Col>
                </Row>*/}



                <Form onSubmit={modificarDatos}>
                    {
                        orderLine.map((i, index) => (

                            <Row className="mb-2 pb-2" key={index}>
                                <Col>
                                    <p>{i.producto}</p>
                                </Col>
                                <Col className="text-center">
                                    <Form.Group controlId={`cantidad-${i.name}`}>
                                        <Form.Control
                                            type="number"
                                            placeholder={cantidades[index]}
                                            value={cantidades[index]}
                                            onChange={(e) => manejarCambio(index, e)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className="text-center">
                                    <Form.Check
                                        type="checkbox"
                                        checked={check[index]}
                                        onChange={(e) => manejarCambioCheck(index, e)}
                                        name={`check-${i.id}`}

                                    />
                                </Col>
                            </Row>
                        ))
                    }
                    <Row>
                        <Col className="text-center">
                            <Button variant="dark" type="submit">
                                Guardar
                            </Button>
                        </Col>
                    </Row>



                </Form>

            </Container >



        </>
    )

    return (

        <Login />

    )
}

export default OnePedido