import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from '../firebaseConfig/firebase';
import { signOut } from "firebase/auth";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';

import ListGroup from 'react-bootstrap/ListGroup';

import Login from './Login';

const Devolucion = () => {

    const navigate = useNavigate();
    const [valido, setValido] = useState();
    const [lista, setLista] = useState([]);
    const [query, setQuery] = useState('');

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const elementosFiltrados = lista.filter((i) =>
        i.name.toLowerCase().includes(query.toLowerCase())
    );

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/Login");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    const handleHome = () => {
        navigate("/");
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                user.getIdToken().then((token) => {
                    console.log(token);
                    hacerSolicitudSegura(token);
                })
                setValido(true);
            } else {
                setValido(false);
                console.log("user is logged out")
            }
        });
    }, [])

    function hacerSolicitudSegura() {
        const urlServidor = 'https://appsescac.com/getDevoluciones'
        const headers = {
            //'Authorization': 'Bearer ' + token // Adjuntar el token al encabezado de autorización
        };
        axios.get(urlServidor, { headers })
            .then(response => {

                const res = response.data.data.value;
                const docs = []
                console.log(res)
                res.forEach((i) => {
                    docs.push({ id: i.id, name: i.name, alumno1: i.partner_id[1], rental_status: i.rental_status, return_date: i.return_date, start_date: i.start_date, alumno2: i.x_studio_many2one_field_djLvM, profesor: i.x_studio_profesor[1], proyecto: i.x_studio_proyecto })
                })
                //console.log(docs)
                setLista(docs);

            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleVer = (name, proyecto, alumno1, rental_status, return_date, start_date, alumno2, profesor) => {

        navigate("/OnePedido?name=" + name + '&proyecto=' + proyecto + '&alumno1=' + alumno1 + '&rental_status='
            + rental_status + '&return_date=' + return_date + '&start_date=' + start_date + '&alumno2' + alumno2 + '&profesor=' + profesor);
    }

    /* APARADOR */
    if (valido) return (
        <>
            <Container>
                <Row>
                    <Col>
                        <button className="mt-2 ms-2" style={{ width: '70px' }} onClick={handleHome}>
                            Volver
                        </button>
                    </Col>
                    <Col>Pendiente de Devolución</Col>
                    <Col>
                        <div className="d-flex justify-content-end">
                            <button className="mt-2 ms-2" style={{ width: '70px' }} onClick={handleLogout}>
                                Salir
                            </button>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3 mt-3">
                    <Col className="d-flex justify-content-center">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={query}
                            onChange={handleInputChange}
                        />
                    </Col>
                </Row>


                <ListGroup>
                    {elementosFiltrados.map((x) => (
                        <ListGroup.Item><Row>
                            <Col ><b>{x.name}</b></Col>
                            <Col >{x.alumno1}</Col>
                            <Col >{x.id}</Col>
                            <Button
                                style={{ width: '70px' }}
                                onClick={() => handleVer(x.name, x.proyecto, x.alumno1, x.rental_status, x.return_date, x.start_date, x.alumno2, x.profesor)}
                                variant="dark"
                                className="me-3"
                            >
                                Ver
                            </Button>

                        </Row></ListGroup.Item>
                    ))}
                </ListGroup>

                <ListGroup>

                </ListGroup >
            </Container >
        </>
    )

    return (

        <Login />

    )
}

export default Devolucion