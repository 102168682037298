import { initializeApp } from "firebase/app";

import { getFirestore } from '@firebase/firestore';

import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBqt3NeN8ViPuBjEp7ZEO9DGzPPUgdHaTA",
  authDomain: "testf-e37df.firebaseapp.com",
  projectId: "testf-e37df",
  storageBucket: "testf-e37df.appspot.com",
  messagingSenderId: "943188746775",
  appId: "1:943188746775:web:d9b1a8b49f4afb45c1f252"
};


const app = initializeApp(firebaseConfig);


export const db = getFirestore(app);
export const auth = getAuth(app);