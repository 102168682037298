import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from '../firebaseConfig/firebase';
import { signOut } from "firebase/auth";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Container } from 'react-bootstrap';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ListGroup from 'react-bootstrap/ListGroup';

import Login from './Login';

const PedidosCheck = () => {

    const navigate = useNavigate();
    const [valido, setValido] = useState();
    const [lista, setLista] = useState([]);
    const [orderLine, setOrderLine] = useState([]);
    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [oneProduct, setOneProduct] = useState();
    const [allProducts, setAllProducts] = useState([]);
    const [encontrados, setEncontrados] = useState([]);
    const [busqueda, setBusqueda] = useState([]);
    const [nameQty, setNameQty] = useState("");
    const [descripcion, setDescripcion] = useState([]);

    const [accesToken, setAccesToken] = useState([]);

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/Login");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                user.getIdToken().then((token) => {
                    console.log(token);
                    hacerSolicitudSegura(token);
                })
                setValido(true);
            } else {
                setValido(false);
                console.log("user is logged out")

            }
        });

    }, [])


    function hacerSolicitudSegura(token) {
        const urlServidor = 'https://appsescac.com/getPedidos'
        const headers = {
            //'Authorization': 'Bearer ' + token // Adjuntar el token al encabezado de autorización
        };
        axios.get(urlServidor, { headers })
            .then(response => {

                const res = response.data.data.value;
                const docs = []
                console.log(res)
                res.forEach((i) => {
                    docs.push({ id: i.id, name: i.name, alumno1: i.partner_id[1], rental_status: i.rental_status, return_date: i.return_date, start_date: i.start_date, alumno2: i.x_studio_many2one_field_djLvM, profesor: i.x_studio_profesor[1], proyecto: i.x_studio_proyecto })
                })
                //console.log(docs)
                setLista(docs);

            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleVer = (name, proyecto, alumno1, rental_status, return_date, start_date, alumno2, profesor) => {

        navigate("/OnePedido?name="+name+'&proyecto='+proyecto+'&alumno1='+alumno1+'&rental_status='
            +rental_status+'&return_date='+return_date+'&start_date='+start_date+'&alumno2'+alumno2+'&profesor='+profesor);
    }

    if (valido) return (
        <>
            <Container>
                <div class="d-flex justify-content-end">
                    <button className="mt-2 ms-2" style={{ width: '70px' }} onClick={handleLogout}>
                        Salir
                    </button>
                </div>
                <div className="mt-0 mt-lg-4 p-3 p-lg-0 p-md-0 p-sm-0 mt-sm-2 border-bottom border-2 border-dark mb-4">
                    <h2 className="m-0 fw-bold ">Salidas</h2>
                </div>
                <ListGroup>
                    {
                        lista.map((x) => (
                            <ListGroup.Item><Row>
                                <Col lg={4}><b>{x.name}</b></Col>
                                <Col lg={4}>{x.alumno1}</Col>
                                <Col lg={2}>{x.id}</Col>
                                <Button
                                    style={{ width: '70px' }}
                                    onClick={() => handleVer(x.name, x.proyecto, x.alumno1, x.rental_status, x.return_date, x.start_date, x.alumno2, x.profesor)}
                                    variant="dark"
                                    className="me-3"
                                >
                                    Ver
                                </Button>
                                
                            </Row></ListGroup.Item>
                        ))
                    }
                </ListGroup >
            </Container >
        </>
    )

    return (

        <Login />

    )
}

export default PedidosCheck