import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from '../firebaseConfig/firebase';
import { signOut } from "firebase/auth";
import axios from 'axios';
import Imprimir from './Imprimir'


import Login from './Login';

const Home = () => {

    const navigate = useNavigate();
    const [valido, setValido] = useState();
    const [lastLogin, setLastLogin] = useState();
    const [email, setEmail] = useState();

    const [cat, setCat] = useState([]);
    const [catPadre, setCatPadre] = useState([]);
    const [product, setProduct] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [encontrados, setEncontrados] = useState([]);
    const [oneProduct, setOneProduct] = useState([]);
    const [busqueda, setBusqueda] = useState([]);
    const [nameQty, setNameQty] = useState("");
    const [qty, setQty] = useState([]);
    const [show, setShow] = useState(false);

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/Login");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    /*const handleChange = e => {
        setBusqueda(e.target.value);
        filtrar(e.target.value);
        if (e.target.value != "")
            setOneProduct([])
        if (e.target.value.length === 0)
            setEncontrados([])
    }

    const filtrar = (terminoBusqueda) => {
        var resultadosBusqueda = allProducts.filter((elemento) => {
            if (elemento.name.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()))
                return elemento;
        })
        setEncontrados(resultadosBusqueda);
        console.log(encontrados)
    }*/

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;

                setEmail(user.email);
                setValido(true);
                setLastLogin(user.metadata.lastLoginAt)

            } else {
                setValido(false);
                console.log("user is logged out")

            }
        });

    }, [])


    // ALL PRODUCTS
    /*useEffect(() => {
        axios.get('http://localhost:3000/getProducts')
            .then(response => {

                const res = response.data.data.value;
                const docs = []
                //console.log(res)

                res.forEach((i) => {
                    if (i.categ_id[1] !== "CONSUMIBLE") {
                        //console.log(i.categ_id[1].substr(4))
                        docs.push({ name: i.name, categ: i.categ_id[1].substr(4), descripcion: i.x_studio_descripcion })
                    }
                })

                setProduct(docs);
                setAllProducts(docs);

            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    // QUANTITY AVAILABLE

    const getLista = async () => {
        axios.get('http://localhost:3000/getQuantity?product_id=' + nameQty)
            .then(response => {
                setQty([]);
                const resQuant = response.data.data.value;

                setQty(resQuant[0].quantity);
                console.log(resQuant[0].quantity)
            })
            .catch(error => {
                console.log(error);
            });
    }



    // Categories Padre
    useEffect(() => {

        const getCateg = async () => {
            axios.get('http://localhost:3000/getCategories')
                .then(response => {

                    const res = response.data.data.value;
                    const docs = []

                    res.forEach((i) => {
                        if (i.parent_id === false) {

                            docs.push(i.name)
                        }

                    })
                    //console.log(docs)
                    setCatPadre(docs);

                })
                .catch(error => {
                    console.log(error);
                });
        }
        getCateg()
    }, [catPadre]);

    useEffect(() => {
        axios.get('http://localhost:3000/getCategories')
            .then(response => {
                setCat(response.data.data.value);
                //console.log(response.data.data.value)
            })
            .catch(error => {
                console.log(error);
            });
    }, []);*/

    const handleRecogida = () => {
        navigate("/recogidas");
    }

    const handleDevolucion = () => {
        navigate("/devoluciones");
    }

    if (valido) return (
        <>
            <button className="mt-2 ms-2" style={{ width: '70px' }} onClick={handleRecogida}>
                Recogida
            </button>
            <button className="mt-2 ms-2" style={{ width: '70px' }} onClick={handleDevolucion}>
                Devolución
            </button>
        </>
    )

    return (

        <Login />

    )
}

export default Home