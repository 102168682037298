import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import Login from './components/Login';
import Imprimir from './components/Imprimir';
import PedidosCheck from './components/PedidosCheck';
import OnePedido from './components/OnePedido';
import Buscador from './components/Buscador';
import Home from './components/Home';
import Recogida from './components/Recogida';
import Devolucion from './components/Devolucion';



function App() {
  return (
    <>
      <Router>
        <div>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/salidas" element={<PedidosCheck />} />
            <Route path="/OnePedido" element={<OnePedido />} />
            <Route path="/buscador" element={<Buscador />} />
            <Route path="/recogidas" element={<Recogida />} />
            <Route path="/devoluciones" element={<Devolucion />} />
            <Route element={
              <div><h1>Página no encontrado 404</h1></div>
            } />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;